<template>
  <div class="static-chart">
    <vue-apex-charts ref="chart" type="area" height="350" :options="chartOptions" :series="series" :key="chartKey" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import config from './chartConfig.js'

export default {
  name: 'StaticChart',
  data() {
    return {
      chartOptions: null,
      chartKey: Math.random().toString(36).substring(2, 15)
    }
  },
  components: {
    VueApexCharts
  },
  props: {
    series: {
      type: Array
    },
    xaxis: {
      type: Object
    },
    title: {
      type: String
    },
    color: {
      type: String
    }
  },
  watch: {
    series: {
      deep: true,
      handler() {
        this.$refs.chart.updateSeries([
          {
            data: this.series[1].data
          }
        ])
      }
    },
    // Update Title when locale changes
    '$i18n.locale'() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          title: {
            text: this.title
          }
        }
      }

      this.chartKey = Math.random().toString(36).substring(2, 15)
    }
  },
  created() {
    this.chartOptions = Object.assign({}, config.chartOptions)

    this.chartOptions = {
      ...this.chartOptions,
      ...{
        stroke: {
          width: [4, 3, 2],
          curve: 'smooth'
        },
        title: {
          text: this.title
        },
        xaxis: {
          categories: this.xaxis.categories,
          labels: {
            style: {
              colors: '#ffffff',
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff',
            }
          }
        },
        legend: {
          labels: {
            colors: '#ffffff',
          }
        },
        colors: ['#fad902', this.color, '#D91656'],
        theme: {
          monochrome: {
            enabled: false,
          }
        }
      }
    }
  },
}
</script>

<style lang="scss">
.static-chart {
  width: 100%;

  .apexcharts-title-text {
    font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  }

  .apexcharts-xaxis-label {
    font-family: 'Lato', Helvetica, Arial, sans-serif !important;
    font-size: 1em;
  }

  .apexcharts-xaxis {
    display: block !important;
  }

  .apexcharts-legend-text {
    margin-right: 0.5rem;
  }
}
</style>
