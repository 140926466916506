<template>
  <div class="chart-info summary-info w-full relative">
    <vx-card v-if="!isLoading" class="summary-info__card">
      <div class="summary-info__data">
        <ul class="summary-info__data__list">
          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Total number of all visiting within the selected date range'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[0]) }}</div>
            <div class="summary-info__data__list__item__value">{{ totalVisitors }}</div>
          </li>
          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Total number of individuals visiting within the selected date range'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[1]) }}</div>
            <div class="summary-info__data__list__item__value">{{ uniqueVisitors }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Total number of VEGA visiting within the selected date range'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[2]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumTotalVegaVisitorsCountPerDay  }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Number of visitors who showed strong buying intent'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[3]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumThresholdVegaVisitorsCountPerDay }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Share of visitors who showed strong buying intent'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[4]) }}</div>
            <div class="summary-info__data__list__item__value">{{ percentVegaVisitorsCountPerDay }}</div>
          </li>
        </ul>

        <!-- CHART -->
        <div class="summary-info__data__chart">
          <static-chart-spline :series="totalVegaVisitorsCountPerDay" :xaxis="xaxisValues" :color="color" />
        </div>
      </div>
    </vx-card>

    <placeholder-text-small v-else />
  </div>
</template>
<script>
import { analyticsData } from './analyticsData'
import StaticChartSpline from '@/components/static-chart-spline/StaticChartSpline.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    StaticChartSpline,
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    },
    series: {
      type: Array
    },
    seriesShowCountPerDay: {
      type: Array
    },
    weekdays: {
      type: Array
    }
  },

  data() {
    return {
      demoSummaryDataLabels: ['Total Visitors', 'Unique Visitors', 'Monitored Visitors (Unique)', 'High Potential Visitors', 'Share of untapped Potential'],
      demoSummaryData: [
        {
          value: '125.000'
        },
        {
          value: '123.000'
        },
        {
          value: '125.000'
        },
        {
          value: '23.000'
        },
        {
          value: '18,7%'
        },
        // {
        //   value: '500'
        // },
      ],
      infoImg: require('@/assets/images/elements/info-light.svg'),
      color: '#f1a342'
    }
  },

  computed: {
    totalVegaVisitorsCountPerDay() {
      return [
        {
          name: 'Total Visitors',
          data: this.seriesShowCountPerDay[0].data
        },
        {
          name: 'Monitored Visitors (Unique)',
          data: this.series[0].totalData
        },
        {
          name: 'High Potential Visitors',
          data: this.series[0].thresholdData
        },
      ]
    },

    thresholdVegaVisitorsCountPerDay() {
      return [
        {
          data: this.series[0].thresholdData
        }
      ]
    },

    sumTotalVegaVisitorsCountPerDay() {
      if (this.series && this.series[0] && this.series[0].totalData) {
        return this.series[0].totalData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      }

      return 0
    },

    sumThresholdVegaVisitorsCountPerDay() {
      if (this.series && this.series[0] && this.series[0].thresholdData) {
        return this.series[0].thresholdData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      }

      return 0
    },

    percentVegaVisitorsCountPerDay() {
      let percent = Math.round((this.sumThresholdVegaVisitorsCountPerDay / this.sumTotalVegaVisitorsCountPerDay) * 100)

      if (isNaN(percent)) {
        percent = 0
      }

      percent = `${percent}%`

      return percent
    },

    summaryData() {
      if (!this.isVegaActivated) {
        return this.demoSummaryData
      }

      const summaryData = this.demoSummaryData

      const totalAllWebsiteVisitorsCount = 0
      let totalWebsiteVisitorsCount = 0
      const uniqueWebsiteVisitorsCount = 0
      let totalVisitorsWithBuyingIntentCount = 0
      let visitorsWithBuyingIntentInPercent = 0
      // let totalConversationRequestsCount = 0

      if (!summaryData || !(summaryData.length > 0)) {
        summaryData[0].value = totalAllWebsiteVisitorsCount
        summaryData[1].value = uniqueWebsiteVisitorsCount
        summaryData[2].value = totalWebsiteVisitorsCount
        summaryData[3].value = totalVisitorsWithBuyingIntentCount
        summaryData[4].value = visitorsWithBuyingIntentInPercent
        // summaryData[3].value = totalConversationRequestsCount

        return summaryData
      }

      if (this.vegaVisitorAnalytics) {
        totalWebsiteVisitorsCount = this.vegaVisitorAnalytics.reduce(
          (accumulator, currentValue) => accumulator + currentValue.visitor_count,
          0
        )

        const totalVisitorsWithBuyingIntentArray = this.vegaVisitorAnalytics.filter((item) => this.selectedVegaPopupThreshhold.includes(item.score_category))
        totalVisitorsWithBuyingIntentCount = totalVisitorsWithBuyingIntentArray.reduce(
          (accumulator, currentValue) => accumulator + currentValue.visitor_count,
          0
        )
        visitorsWithBuyingIntentInPercent = Math.round((totalVisitorsWithBuyingIntentCount / totalWebsiteVisitorsCount) * 100)
        if (isNaN(visitorsWithBuyingIntentInPercent)) {
          visitorsWithBuyingIntentInPercent = 0
        }
        visitorsWithBuyingIntentInPercent = `${visitorsWithBuyingIntentInPercent}%`
      }

      // if (this.vegaConversationStatus) {
      //   totalConversationRequestsCount = this.vegaConversationStatus.reduce(
      //     (accumulator, currentValue) => accumulator + currentValue.visitor_count,
      //     0
      //   )
      // }

      summaryData[1].value = this.totalVisitors
      summaryData[2].value = this.uniqueVisitors
      summaryData[3].value = totalWebsiteVisitorsCount
      summaryData[4].value = totalVisitorsWithBuyingIntentCount
      summaryData[5].value = visitorsWithBuyingIntentInPercent
      // summaryData[3].value = totalConversationRequestsCount

      return summaryData
    },
    xaxisValues() {
      return {
        categories: this.weekdays
      }
    },
    title() {
      return this.$i18n.t('views.home.customerReqeuestsCountPerDay')
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}

.summary-info {
  &__card {
    background: rgb(39, 93, 115);
  }

  &__data {
    display: flex;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    &__list {
      padding: 20px 80px 20px 0;
      min-width: 35%;

      @media (max-width: 768px) {
        padding: 20px 0 0;
        width: 100%;
      }

      &__item {
        display: flex;
        margin-top: 35px;

        @media (max-width: 768px) {
          margin-top: 30px;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:nth-of-type(3) {
          margin-top: 90px;

          @media (max-width: 768px) {
            margin-top: 50px;
          }
        }

        &__label {
          flex: 1;
          color: rgb(255, 255, 255);
          font-size: 20px;
          font-weight: 700;
        }

        &__value {
          margin-left: 60px;
          min-width: 40px;
          color: rgb(255, 255, 255);
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    &__chart {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;

      @media (max-width: 768px) {
        margin-bottom: 0;
        padding-top: 20px;
        width: 100%;
      }
    }
  }
}
</style>
