var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info summary-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", { staticClass: "summary-info__card" }, [
            _c("div", { staticClass: "summary-info__data" }, [
              _c("ul", { staticClass: "summary-info__data__list" }, [
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: "Total number of all visiting within the selected date range",
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[0])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.totalVisitors))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: "Total number of individuals visiting within the selected date range",
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[1])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.uniqueVisitors))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: "Total number of VEGA visiting within the selected date range",
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[2])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.sumTotalVegaVisitorsCountPerDay))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: "Number of visitors who showed strong buying intent",
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[3])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.sumThresholdVegaVisitorsCountPerDay))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: "Share of visitors who showed strong buying intent",
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[4])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.percentVegaVisitorsCountPerDay))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "summary-info__data__chart" },
                [
                  _c("static-chart-spline", {
                    attrs: {
                      series: _vm.totalVegaVisitorsCountPerDay,
                      xaxis: _vm.xaxisValues,
                      color: _vm.color,
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }